export const MenuOptionButton = (props: {
  title: string;
  onClick: () => void;
  className?: string;
}) => {
  return (
    <div
      onClick={props.onClick}
      className={`py-6 text-4xl cursor-pointer hover:opacity-60 ${props.className}`}
    >
      {props.title}
    </div>
  );
};
