import { useState } from "react";
import { INSTRUCTIONS } from "../menu/HowToPlay";
import { StandardButton } from "../components/buttons/StandardButton";

export const HowToPlayCarousel = () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  return (
    <div className="flex flex-col items-center unselectable">
      <h1 className="text-4xl font-mencken">HOW TO PLAY</h1>
      <div className="flex flex-row justify-center items-center m-6 font-hoss font-light">
        {INSTRUCTIONS.map((stage, i) => {
          return (
            <div
              className="flex flex-row justify-center items-center"
              key={`stageMarker_${i}`}
            >
              <div className="relative" onClick={() => setCurrentSlideIndex(i)}>
                <div
                  className={`rounded-[50%] cursor-pointer w-6 h-6 relative ${
                    currentSlideIndex == i ? "bg-fuschia" : "bg-transparent"
                  } border border-chocolate`}
                >
                  <p className="text-sm absolute bottom-0 right-[50%] translate-x-[50%]">
                    {i + 1}
                  </p>
                </div>
              </div>
              {i == INSTRUCTIONS.length - 1 ? null : (
                <div className="h-[2px] w-8 bg-chocolate"></div>
              )}
            </div>
          );
        })}
      </div>
      {INSTRUCTIONS.map((section, i) => (
        <div
          className="flex flex-col items-center text-center font-hoss font-light"
          key={`rule_${i}`}
          style={{ display: currentSlideIndex == i ? "block" : "none" }}
        >
          <h1 className="text-2xl mb-4">{section.title}</h1>
          <div className="flex flex-row items-center justify-center">
            <div
              className={`relative -top-[100px] cursor-pointer bg-cream p-2 m-4 rounded-[60%] border border-chocolate ${
                currentSlideIndex <= 0 && "opacity-0 cursor-pointer"
              }`}
              onClick={() =>
                currentSlideIndex <= 0
                  ? null
                  : setCurrentSlideIndex(currentSlideIndex - 1)
              }
            >
              <img className="w-4" src="/assets/leftCarat.png" />
            </div>
            <div className="flex flex-col items-center w-[calc(min(300px,80%))]">
              {section.gif_url && (
                <div className="m-2 border border-chocolate rounded-[6px] flex flex-col">
                  <div className="border-[16px] border-cream rounded-[6px]">
                    <img
                      src={section.gif_url}
                      className="border border-chocolate rounded-[6px] "
                    />
                  </div>
                </div>
              )}
              <div className="font-hoss uppercase text-center text-base h-[200px] overflow-scroll my-2 flex flex-col">
                <p>{section.rule}</p>
              </div>
            </div>
            <div
              className={`relative -top-[100px] cursor-pointer bg-cream p-2 m-4 rounded-[60%] border border-chocolate ${
                currentSlideIndex >= INSTRUCTIONS.length - 1 &&
                "opacity-0 cursor-pointer"
              }`}
              onClick={() =>
                currentSlideIndex >= INSTRUCTIONS.length - 1
                  ? null
                  : setCurrentSlideIndex(currentSlideIndex + 1)
              }
            >
              <img className="w-4" src="/assets/rightCarat.png" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
