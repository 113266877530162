import { initializeApp } from "@firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCEPCE4ZEkOQFfrUOVfA058pCXvLhb2Rt8",
  authDomain: "dating-bingo-v2.firebaseapp.com",
  projectId: "dating-bingo-v2",
  storageBucket: "dating-bingo-v2.appspot.com",
  messagingSenderId: "219859444597",
  appId: "1:219859444597:web:7a373e13357082bef4a93c",
  measurementId: "G-8RC9CM5GGZ",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;
