import { useState, useEffect } from "react";

interface ScreenDimensions {
  width: number;
  height: number;
}

export function useScreenDimensions(): ScreenDimensions {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    document.documentElement.style.setProperty("--screen-height", `${window.innerHeight}px`);
    document.documentElement.style.setProperty("--screen-width", `${window.innerWidth}px`);
  }

  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return { width: width, height: height };
}
