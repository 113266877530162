import { useNavigate } from "react-router-dom";
import { BoardThumbnailImage } from "./BoardThumbnailImage";
import { useEffect } from "react";

export const CreateBoardThumbnail = () => {
  const navigate = useNavigate();

  return (
    <div
      className="m-4 cursor-pointer"
      onClick={() => navigate("/create-board")}
    >
      <BoardThumbnailImage size={5} title={"CREATE NEW BOARD"} className="bg-teal opacity-[15%] p-[24px]" />
    </div>
  );
};
