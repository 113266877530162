export const getBorderAttributeForCell = (
  size: number,
  row: number,
  col: number
) => {
  if (row == 0 && col == 0) {
    return "rounded-tl-xl";
  } else if (row == 0 && col == size - 1) {
    return "rounded-tr-xl";
  } else if (row == size - 1 && col == 0) {
    return "rounded-bl-xl";
  } else if (row == size - 1 && col == size - 1) {
    return "rounded-br-xl";
  }
  return "";
};
