import { useEffect, useState } from "react";
import { SecondaryLogo } from "../logos/SecondaryLogo";
import { StandardButton } from "../components/buttons/StandardButton";
import { Avatar, Avatars, getAvatarPathForId } from "../utils/helpers";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { CloseMenuButton } from "../components/buttons/CloseMenuButton";
import { Images } from "../utils/Images";

interface CustomizeAccountPageProps {
  isNewUser: boolean;
  onClose?: () => void;
}

export const CustomizeAccountPage = (props: CustomizeAccountPageProps) => {
  const { isNewUser, onClose } = props;
  const [name, setName] = useState<string>("");
  const [selectedAvatar, setSelectedAvatar] = useState<Avatar>(Avatar.PINK);
  const authInfo = useAuth();
  const navigate = useNavigate();
  const params = useParams()

  useEffect(() => {
    const userInfo = authInfo.currentUserInfo;
    if (userInfo) {
      setName(userInfo.displayName);
      setSelectedAvatar(userInfo.avatarId);
    }
  }, [authInfo.currentUserInfo]);

  const createUser = () => {
    const uid = authInfo.currentFirebaseUser?.uid;
    if (!uid) {
      return; //TODO: ERROR HANDLE
    }
    fetch(`/user/createOrUpdate`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        uid: uid,
        displayName: name,
        avatarId: selectedAvatar,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        authInfo.setCurrentUserInfo(data); // we shouldn't really be setting the value of gameIds here but it's fine
        onClose && onClose();
        if (params.inviteCode && params.boardId) {
          navigate(`/join-board/${params.boardId}/${params.inviteCode}`);
        } else {
          navigate("/boards");
        }
      });
  };

  const avatarIcons = Avatars.map((avatar: Avatar) => {
    return (
      <img
        key={`icon_${avatar}`}
        onClick={() => setSelectedAvatar(avatar)}
        className={`w-[50px] m-[2px] p-[5px] rounded-[50%] ${
          selectedAvatar == avatar && "bg-fuschia60"
        }`}
        src={`/assets/avatars/${getAvatarPathForId(avatar)}`}
      />
    );
  });

  const title = isNewUser ? "WELCOME TO THE CLUB" : "YOUR ACCOUNT";
  const usernameTitle = isNewUser
    ? "LET'S GET YOU SET UP"
    : "CHOOSE A DISPLAY NAME";
  const avatarTitle = "CHOOSE A BB";
  const buttonTitle = isNewUser ? "LET'S GO!" : "CONFIRM";

  return (
    <div>
      <div className={`flex flex-col items-center relative top-[120px]`}>
        <h1 className="m-4 text-4xl text-center">{title}</h1>
        <h2 className="text-xl font-hoss font-light mt-2">{usernameTitle}</h2>
        <h2 className="text-sm font-hoss font-light uppercase mb-2">
          {"(max 10 characters)"}
        </h2>
        <input
          placeholder="DISPLAY NAME"
          maxLength={10}
          type="text"
          className="input text-xl m-3 px-1 border-b border-solid bg-transparent font-hoss font-light text-center uppercase"
          value={name}
          onChange={(e) => setName(e.target.value)}
        ></input>
        <h2 className="text-xl font-hoss font-light m-2">{avatarTitle}</h2>
        <div className="flex flex-col">
          <div className="flex flex-row">
            {avatarIcons.slice(0, Math.floor(avatarIcons.length / 2))}
          </div>
          <div className="flex flex-row">
            {avatarIcons.slice(
              Math.floor(avatarIcons.length / 2),
              avatarIcons.length
            )}
          </div>
        </div>
        <StandardButton
          className="bg-orange text-cream py-1 px-3 text-xl m-8"
          text={buttonTitle}
          onClick={createUser}
          disabled={name == ""}
        />
      </div>
    </div>
  );
};
