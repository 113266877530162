import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useParams } from "react-router-dom";

const WithSignupFlowRoute = ({ children }) => {
  const authInfo = useAuth();
  const params = useParams();
  const stringifiedParams = Object.values(params).join("/");

  if (authInfo.currentFirebaseUser) {
    return children;
  }

  return <Navigate to={`/login/${stringifiedParams}`} />;
};

export default WithSignupFlowRoute;
