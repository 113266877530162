import { IUserShortInfo } from "../interfaces/IUserShortInfo";
import { getAvatarPathForId } from "../utils/helpers";

interface BoardThumbnailPlayersTrayProps {
  users: IUserShortInfo[];
}

export const BoardThumbnailPlayersTray = (
  props: BoardThumbnailPlayersTrayProps
) => {
  return (
    <div className="flex m-2 align-center justify-center">
      {props.users.slice(0, 6).map((user: IUserShortInfo) => {
        return (
          <img
            key={`players_tray_${user.id}`}
            className="w-8 mx-1"
            src={`/assets/avatars/${getAvatarPathForId(user.avatarId)}`}
          />
        );
      })}
    </div>
  );
};
