import { ReactNode } from "react";
import { getBorderAttributeForCell } from "../utils/boardHelpers";

interface BoardThumbnailImageProps {
  size: number;
  title: string;
  className: string;
  players?: ReactNode;
}

export const BoardThumbnailImage = (props: BoardThumbnailImageProps) => {
  const size = props.size;
  const board = Array(size)
    .fill(null)
    .map(() => Array(size).fill(null));

  return (
    <div className="relative border-[0.5px] border-solid border-black rounded-xl">
    <div className="uppercase flex-col absolute max-w-[150px] text-center text-4xl flex items-center justify-center top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4">
        {props.title}
        {props.players}
      </div>
      {board.map((row, i) => {
        return (
          <div className="flex" key={`thumbnail_row_${i}`}>
            {row.map((_cell, j) => {
              const borderStyle = `${getBorderAttributeForCell(
                size,
                i,
                j
              )} border-[0.5px] border-solid border-darkgray`;
              return (
                <div
                  key={`thumbnail_cell_${i}.${j}`}
                  className={`flex ${borderStyle}`}
                >
                  <div
                    className={`border-none ${borderStyle} ${props.className}`}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
