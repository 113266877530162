import { useState } from "react";
import { StandardButton } from "../components/buttons/StandardButton";
import { MenuButton } from "../components/buttons/MenuButton";
import { HomePageLogo } from "../logos/HomePageLogo";
import { useNavigate } from "react-router-dom";
import { MenuPage } from "../menu/MenuPage";
import { useAuth } from "../contexts/AuthContext";
import { HowToPlayCarousel } from "./HowToPlayCarousel";
import { LoadingDeb } from "../loading/loadingDeb";

export const HomePageEntryOptions = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const linkClassName = "underline";

  return (
    <div className={`flex flex-col`}>
      <div className={`flex flex-row w-[100%] items-start justify-between`}>
        <HomePageLogo />
        <div className="flex flex-row pt-6 pr-4">
          <StandardButton
            className="mx-1 bg-teal py-1 w-[70px] text-cream text-xl"
            text="PLAY"
            onClick={() => navigate("/login")}
          />
          <StandardButton
            className="mx-1 bg-cream py-1 w-[70px] text-chocolate text-xl"
            text="WATCH"
            onClick={() => navigate("/spectate")}
          />
        </div>
      </div>
      <div className="flex flex-col w-[100%] items-center">
        <div className="my-2 w-[100%] flex flex-col items-center">
          <img src="/assets/homepage.gif" className="w-[min(500px,85%)]" />
        </div>
        <h1 className="font-mencken text-4xl unselectable mb-4 text-center uppercase w-[50vw]">
          Welcome to Dating Bingo!
        </h1>
        <div className="font-hoss font-light my-2 mx-6 max-w-[600px] p-4 bg-cream unselectable">
          <p>Dear friends,</p>
          <br />
          <p>
            Don't worry, this isn’t another dating app. It's literally bingo,
            but for dating. Dating can already be a ridiculous game, so we just made
            it official!
          </p>
          <br />
          <p>
            We created this game to force ourselves to date and to make it more
            of a light, low-pressure thing rather than the impossible pursuit of
            love/sex it can often feel like. It’s built on the premise that you
            never know who you’ll vibe with, and that the more people you meet,
            the more fun you’ll have - regardless of whether you find a casual
            fling, a new friend, or a long-term partner along the way.
          </p>
          <br />
          <p>
            We learned a lot playing our{" "}
            <a
              className={linkClassName}
              target="_blank"
              href="https://www.datingbingo.club/watch-board/lb4iv16kWqnLfrKwFQEl/812390"
            >
              first game
            </a>{" "}
            over the summer - check out who won!
          </p>
          <br />

          <p>
            Make your own board, invite some friends to play, and set some high
            stakes for winning/losing. Share with your boring coupled friends so
            they can follow along. We’d love any kind of feedback, from bug
            reports to design notes to feature ideas. Please tell us via{" "}
            <a
              className={linkClassName}
              target="_blank"
              href="https://www.instagram.com/datingbingo/"
            >
              Instagram
            </a>{" "}
            or{" "}
            <a
              className={linkClassName}
              target="_blank"
              href="https://9wlkc62ji55.typeform.com/to/YdKRdrib"
            >
              anonymous survey
            </a>
            .
          </p>
          <br />
          <p>XOXO, Elana & Ammagrace</p>
        </div>
      </div>
      <div className="pt-12 px-4">
        <HowToPlayCarousel />
      </div>
      <div className="m-4 font-hoss font-light uppercase text-xs text-end bg-avocado">
        © 2023 Elana Shen & Ammagrace Chang
      </div>
    </div>
  );
};
