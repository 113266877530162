import { useNavigate } from "react-router-dom";
import { IUserShortInfo } from "../interfaces/IUserShortInfo";
import { BoardThumbnailPlayersTray } from "./BoardThumbnailPlayersTray";
import { BoardThumbnailImage } from "./BoardThumbnailImage";

interface BoardThumbnailProps {
  size: number;
  title: string;
  users: IUserShortInfo[];
  url?: string;
}

export const BoardThumbnail = (props: BoardThumbnailProps) => {
  const { size, title, users, url } = props;
  const navigate = useNavigate();

  return (
    <div
      className="m-4 cursor-pointer"
      onClick={url ? () => navigate(url) : undefined}
    >
      <BoardThumbnailImage
        size={size}
        title={title}
        players={ <BoardThumbnailPlayersTray users={users} />}
        className="bg-cream p-[24px]"
      />
    </div>
  );
};
