import { Fragment, useEffect, useState } from "react";
import { MainBoardPage } from "./MainBoardPage";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { JoinBoardDialog } from "./dialog/JoinBoardDialog";

export const JoinBoardPage = () => {
  const { boardId, inviteCode } = useParams();
  const [inviteValidated, setInviteValidated] = useState<boolean>(false);
  const [joinBoardDialogOpen, setJoinBoardDialogOpen] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const authInfo = useAuth();

  useEffect(() => {
    if (boardId && inviteCode && authInfo) {
      fetch(
        `/bingo_board/validate_invite/${encodeURIComponent(
          boardId
        )}/${encodeURIComponent(inviteCode)}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            userId: authInfo.currentUserInfo?.id,
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setInviteValidated(data);
          if (!data) {
            navigate("/boards");
          }
          setJoinBoardDialogOpen(true);
        });
    }
  }, [boardId, inviteCode, authInfo]);
  return (
    <>
      {boardId ? (
        <JoinBoardDialog
          open={joinBoardDialogOpen}
          handleExit={() => setJoinBoardDialogOpen(false)}
          boardId={boardId}
        />
      ) : null}
      <MainBoardPage />
    </>
  );
};
